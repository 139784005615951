<template>
  <div>
    <!-- <GeneralBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    /> -->
    <TimingBlock
      :sequence="sequence"
      @settings-changed="$emit('settings-changed', $event)"
    />
    
  </div>
</template>
<script>
const TimingBlock = () => import('./timeline/TimingBlock')

export default {
  components: {
    TimingBlock
  },

  props: {
    gui: {
      required: true, 
      type: Object,
    },
    sequence: {
      required: true,
      type: Object
    }
  }
}

</script>
